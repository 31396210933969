/* Select2 fixes */
.has-error .select2-selection {
  border: 1px solid #a94442!important;
}
.has-success .select2-selection {
  border: 1px solid #3c763d!important;
}
.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee!important;
  cursor: not-allowed;
}