.table > thead > tr,
.table > tfoot > tr {
  background-color: #343f47;
  color: #ffffff;
}

.table > thead > tr > th,
.table > tfoot > tr > th {
  border: none;
  padding: 10px 15px !important;
}

.table > tbody > tr > td {
  vertical-align: middle;
  padding: 8px 10px !important;
}

/* Table checkbox */
.table > thead > tr > th.datatable-checkbox,
.table > tbody > tr > td.datatable-checkbox,
.table > tfoot > tr > th.datatable-checkbox {
  width: 1%;
  white-space: nowrap;
  padding: 0 10px!important;
  text-align: center;
  vertical-align: middle;
}

.table > thead > tr > th.datatable-checkbox input,
.table > tbody > tr > td.datatable-checkbox input,
.table > tfoot > tr > th.datatable-checkbox input {
  width: 20px;
  height: 20px;
  margin: 0;
  vertical-align: -3px;
}

/* Table actions */
.table > tbody > tr[data-action] {
  cursor: pointer;
}

.table > thead > tr > th.actions,
.table > tbody > tr > td.actions,
.table > tfoot > tr > th.actions {
  width: 1%;
  white-space: nowrap;
  padding: 5px 10px!important;
}

.table > tbody > tr > td.actions a.btn,
.table > tbody > tr > td.actions button.btn {
  margin-right: 5px;
}

.table > tbody > tr > td.actions a.btn:last-child,
.table > tbody > tr > td.actions button.btn:last-child {
  margin-right: 0;
}

.table > tbody > tr > td.actions > .count-1 {
  width: 38px;
}
.table > tbody > tr > td.actions > .count-2 {
  width: 82px;
}
.table > tbody > tr > td.actions > .count-3 {
  width: 126px;
}
.table > tbody > tr > td.actions > .count-4 {
  width: 170px;
}
.table > tbody > tr > td.actions > .count-5 {
  width: 214px;
}

/* Table search */
.table > tfoot > tr > th.search {
  padding: 5px !important;
}
.table > tfoot > tr > th.search.search-text input {
  min-width: 60px;
}
.table > tfoot > tr > th.search.search-number input {
  min-width: 70px;
}
.table > tfoot > tr > th.search.search-select select{
  min-width: 120px;
}
.table > tfoot > tr > th.search.search-number-range {
  > div {
    min-width: 240px;
  }

  @media (min-width: 992px) {
    .number-from {
      padding-right: 5px;
    }
    .number-to {
      padding-left: 5px;
    }
  }
}
.table > tfoot > tr > th.search.search-clear button {
  width: 100%;
  height: 100%;
}