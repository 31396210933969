.ms-container {
  width: auto !important;
}

.ms-container .custom-header {
  background-color: #dddddd;
  padding: 5px 10px 7px;
  margin-bottom: -2px;
  text-align: center;
}

.ms-container .ms-list {
  max-height: 300px;
  height: auto !important;
  min-height: 100px;
}