.text-error {
  color: #cc0000;
}

.text-success {
  color: #00cc00;
}

.display-none {
  display: none;
}

.loader {
  margin: 10px;
  text-align: center;
  color: #ccc;
}