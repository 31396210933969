/* Button addon right */
.btn-addon.btn-addon-right i {
  float: right;
  margin: -7px -13px -7px 12px;
}
.btn-lg.btn-addon.btn-addon-right i {
  margin: -11px -17px -11px 16px;
}
.btn-sm.btn-addon.btn-addon-right i {
  margin: -6px -11px -6px 10px;
}
.btn-xs.btn-addon.btn-addon-right i {
  margin: -2px -6px -2px 5px;
}
.btn-rounded.btn-addon.btn-addon-right i {
  margin: -7px -16px -7px 15px;
}
.btn-sm.btn-rounded.btn-addon.btn-addon-right i {
  margin: -7px -16px -7px 10px;
}
/* end Button addon right */

.btn-half {
  width: 49%;
}
.action-bar form {
  display: inline-block;
}