.top-menu .navbar-left > li > a:hover {
  background-color: #f1f1f1;
}

.top-menu .navbar-left > li.active > a {
  background-color: #eee;
}

.menu.accordion-menu li > a {
  padding: 15px 12px;
}

.menu.accordion-menu li > a > .arrow:before {
  content: "\f105";
}
.menu.accordion-menu li.open > a > .arrow:before {
  content: "\f104";
}