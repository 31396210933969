.tasks li a .task-icon {
  padding-top: 7px;
}
.tasks li a .task-icon i {
  margin-right: 0;
}
.tasks li a .badge {
  max-width: 200px;
  margin: 0;
}
.tasks li a .task-title {
  display: block;
  margin-top: 4px;
  margin-left: 35px;
}
.tasks li a .task-details {
  width: 100%;
  margin-bottom: 4px;
}